import React, { Component } from 'react';
import './chatStyle.css';

class Chat extends Component{

  constructor(){
    super();
    this.getInput = this.getInput.bind(this);
    this.submitMessage = this.submitMessage.bind(this);
    this.leave = this.leave.bind(this);
    this.search = this.search.bind(this);
    this.loadHome = this.loadHome.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
    this.state = {
      input:'',
      dialog:'Connecting...',
      connected:false,
      search:false
    };
  }

  componentDidUpdate(){
    this.scrollToBottom();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({dialog: this.state.dialog + '\n' + 'Friend:' + nextProps.output});
    if (nextProps.connected !== this.state.connected) {
      if(nextProps.connected){
        this.setState({
          dialog: this.state.dialog + '\n' + 'Connected!',
          connected:true,
          search:false
        });
      }else{
        this.setState({
          dialog: this.state.dialog + '\n' + 'Disconnected!',
          connected:false,
          search:true
        });
      }
    }
  }

  getInput(event){
    this.setState({input:event.target.value});
  }

  checkEnter(event){
    if(event.key == 'Enter'){
      this.submitMessage();
    }
  }

  submitMessage(){
    this.props.submitMessage(this.state.input);
    if(this.state.input != ''){
      this.setState({
        dialog:this.state.dialog + '\n' + 'You:' + this.state.input,
        input:''
      });
      this.scrollToBottom();
    }
  }

  leave(){
    this.props.leave();
  }

  search(){
    this.props.search();
    this.setState({
      dialog:'Connecting...',
      search:false
  });
  }

  loadHome(){
    this.props.loadHome();
  }

  scrollToBottom(){
    if(this.textLog){
        this.textLog.scrollTop = this.textLog.scrollHeight;
    }
  };

  render() {
    return(
      <html lang="eng">
        <head>
          <title>Chat</title>
          <meta charset="utf-8"/>
        </head>
        <body>
          <div class="home">
            <img id="homeButton" src={require("./images/icons/home.png")} width="150px" height="150px" onClick={this.loadHome}></img>
          </div>
          <textarea ref={textLog => this.textLog = textLog} id="dialogBox" value={this.state.dialog} disabled="disabled"></textarea>
          <input id="inputBox" value={this.state.input} onChange={this.getInput} onKeyUp={this.checkEnter}/>
          {!this.state.search ? <button id="send" onClick={this.submitMessage}>Send</button> : null}
          {!this.state.search ? <button id="leave" onClick={this.leave}>Leave</button> : null}
          {this.state.search ? <button id="search" onClick={this.search}>search</button> : null}
        </body>
      </html>
    );
  }
}

export default Chat;
