import React, { Component } from 'react';
import './langlearnStyle.css';

class Homepage extends Component{

  constructor(props){
    super(props);
    this.onGo = this.onGo.bind(this);

    this.state = {
      chatLoaded:false,
      ownValue:'',
      learnValue:'',
      langs:["japanese", "french", "english", "german", "italian", "russian", "swedish", "dutch", "spanish", "chinese"],
      left:false,
      right:false,
      matches:0
    };
  }

  componentWillReceiveProps(nextProps){
    this.setState({matches:nextProps.matches});
  }

  componentDidMount(){
    const langlearn = document.createElement("script");
    langlearn.src = "/langlearn.js";
    langlearn.async = true;
    document.body.appendChild(langlearn);
  }

  onGo(){
    this.props.loadChat(this.state.ownValue, this.state.learnValue);
  }

  render() {
    return(
      <html lang="eng">
        <head>
          <title>Chat</title>
          <meta charset="utf-8"/>
        </head>
        <body>
          <div id="homepage">
            <div class="background">
              <canvas id="rain" width="960px" height="720px"></canvas>
            </div>
            <h1 id="available" align="center">Available languages</h1>
            <div class="flags">
              <img id="japan" src={require('./images/flags/japan.png')} class='flag'/> <img id="uk" src={require('./images/flags/uk.png')} class='flag'/>
              <img id="france" src={require('./images/flags/france.png')} class='flag'/> <img id="germany" src={require('./images/flags/germany.png')} class='flag'/>
              <img id="italy" src={require('./images/flags/italy.png')} class='flag'/> <img id="spain" src={require('./images/flags/spain.png')} class='flag'/>
              <img id="russia" src={require('./images/flags/russia.png')} class='flag'/><img id="netherlands" src={require('./images/flags/netherlands.png')} class='flag'/>
              <img id="china" src={require('./images/flags/china.png')} class='flag'/> <img id="sweden" src={require('./images/flags/sweden.png')} class='flag'/>
            </div>
            <h1 id="title">Langlearn</h1>
            <div class='center'>
            </div>
              <div class="center2">
                <input type="text" id="ownBox" placeholder="Yours"/>
                <button id="go" onClick={this.onGo}>Go!</button>
                <input type="text" id="learnBox" placeholder="Learning"/>
              </div>
              <div class="center">
                <h1 id="introText">Langlearn connects you to someone that speaks the language you are learning, and is learning the language you are speaking!</h1>
                <h1 id="instructText">Just enter the language you speak in the left textbox, then the language you are learning in the right textbox, and click go!</h1>
              </div>
          </div>
        </body>
      </html>
    );
  }
}

export default Homepage;
