import React, { Component } from 'react';
import Homepage from './homepage';
import Chat from './chat';
import * as io from 'socket.io-client';

const socket = io();

class App extends Component{

  constructor(props){
    super(props);
    this.loadChat = this.loadChat.bind(this);
    this.loadHome = this.loadHome.bind(this);
    this.submitMessage = this.submitMessage.bind(this);
    this.leave = this.leave.bind(this);
    this.search = this.search.bind(this);

    this.state = {
      chatLoaded:false,
      output:'',
      connected:false,
      matches:0
    };
  }

  componentWillMount(){
    socket.on('message', function(message){
      this.setState({output:message});
    }.bind(this));

    socket.on('connected', function(){
      this.setState({connected:true});
    }.bind(this));

    socket.on('disconnected', function(){
      this.setState({connected:false});
    }.bind(this));

    socket.on('checkFinish', function(availableUsers){
      this.setState({matches:availableUsers});
    }.bind(this));
  }

  loadChat(own, learn){
    socket.emit('addUser', own, learn);
    this.setState({chatLoaded:true});
  }

  submitMessage(input){
    if(this.state.connected && input != ''){
      socket.emit('message', input);
    }
  }

  leave(){
    socket.emit('leave');
  }

  search(){
    socket.emit('search');
  }

  loadHome(){
    socket.emit('home');
    this.setState({chatLoaded:false});
  }

  render() {
    return(
      <div>
        {this.state.chatLoaded ? null : <Homepage loadChat={this.loadChat} matches={this.state.matches}/>}
        {this.state.chatLoaded ? <Chat loadHome={this.loadHome} submitMessage={this.submitMessage} output={this.state.output} connected={this.state.connected} leave={this.leave} search={this.search}/> : null}
      </div>
    );
  }
}

export default App;
